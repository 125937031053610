<template>
    <div>
        <CRow v-show="mostrarEmpresas">
            <CCol md="3">
                <multiselect 
                    v-show="mostrarEmpresas"
                    class="m-0 d-inline-block mb-2" 
                    v-model="empresaSelected"
                    deselect-label="" 
                    label="nombreEmpresa" 
                    placeholder="Mi Empresa" 
                    select-label=""
                    selectedLabel=""
                    :options="empresasTrabajador" 
                    :searchable="true"
                    :loading="isLoadingEmpresas"
                    @select="seleccionarEmpresa">
                </multiselect>
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CCard class="tarjetaGesto">
                    <CCardHeader>
                        <h4>Mis Documentos</h4>
                    </CCardHeader>
                    <CCardBody>
                        <CTabs :active-tab.sync="actTab">
                            <CTab>
                                <template slot="title">
                                    <CIcon :content="$options.freeSet.cilSpreadsheet" /> Liquidaciones
                                </template>
                                <CRow style="padding: 10px;">
                                    <CCol>
                                        <CRow>
                                            <!--<CCol md="3">
                                                <multiselect class="m-0 d-inline-block mb-2" 
                                                    v-model="periodoSelected"
                                                    deselect-label="" 
                                                    label="nombrePeriodo" 
                                                    placeholder="Año" 
                                                    select-label=""
                                                    selectedLabel=""
                                                    :options="periodos" 
                                                    :searchable="true"
                                                    :loading="isLoadingPeriodos"
                                                    @select="seleccionarPeriodo">
                                                </multiselect>
                                            </CCol>-->
                                            <CCol md="2">
                                                <date-picker
                                                    style="width: 100%"
                                                    :editable="false"
                                                    :clearable="false"
                                                    :format="'YYYY'"
                                                    default-panel="year"
                                                    type="year"
                                                    class="mb-2"
                                                    :lang="langPicker" 
                                                    v-model="anioSelected"
                                                    :confirm="false" 
                                                    :confirm-text="'Aceptar'"
                                                    :disabled-date="disabledAnio"
                                                    @change="seleccionarPeriodo"
                                                    >
                                                </date-picker><!--@change="seleccionarPeriodo"-->
                                            </CCol>
                                        </CRow>
                                        
                                        <CargandoInfo :mostrarCarga="isLoadingLiquidaciones" :mensajeInfo="'Obteniendo sus Documentos...'" />
                                        
                                        <CRow v-if="!isLoadingLiquidaciones && periodoSelected != null && documentos.length==0">
                                            <CCol sm="12" md="12" lg="12">
                                                <center>
                                                    <img src="/img/logo2.png" size="custom-size" :height="30" class="mt-3 mb-3" /> Su empleador no ha subido documentos para este periodo
                                                </center>
                                            </CCol>
                                        </CRow> 

                                        <CRow v-show="!isLoadingLiquidaciones">
                                            <CCol sm="6" md="4" lg="4" v-bind:key="documento.mes" v-for="documento in documentos">
                                                <CCard :class="documento.documentos.length>0?'tarjetaGestoDocumentosHay':'tarjetaGestoDocumentosNoHay'">
                                                    <CCardHeader :style="documento.documentos.length>0?'':'background-color:#E57373'">
                                                        <div class="card-header-actions">
                                                            <CLink class="card-header-action btn-minimize float-right" @click="documento.isCollapsed = !documento.isCollapsed; (documento.isCollapsed && documento.docSeleccionado == null)?documento.docSeleccionado = documento.documentos[0]:0">
                                                                <CIcon :name="`cil-chevron-${documento.isCollapsed ? 'top' : 'bottom'}`"/>
                                                            </CLink>

                                                            <CDropdown class="float-right" :caret="false">
                                                                <template #toggler>
                                                                    <CLink class="card-header-action btn-setting">
                                                                        <CIcon :content="$options.freeSet.cilSettings" />
                                                                    </CLink>
                                                                </template>

                                                                <div v-if="documento.documentos.length==0">
                                                                    <CDropdownItem> 
                                                                        <CIcon class="mr-2" :content="$options.freeSet.cilMeh" />
                                                                        No hay documentos
                                                                    </CDropdownItem>
                                                                </div>
                                                                <div v-else role="group" v-bind:key="doc.nombreDocumento" v-for="doc, index in documento.documentos">
                                                                    <!--<CDropdownItem @click="descargarDocumento(documento)">
                                                                        <CIcon :content="$options.freeSet.cilCloudDownload" /> Descargar
                                                                    </CDropdownItem>
                                                                    <CDropdownItem @click="verDocumento(documento)">
                                                                        <CIcon :content="$options.freeSet.cilFile" /> Ver
                                                                    </CDropdownItem>-->
                                                                    <CDropdownHeader>{{doc.nombreDocumento}}</CDropdownHeader>

                                                                    <CDropdownItem @click="seleccionarDocumentoVer(documento, doc); documento.isCollapsed = true">
                                                                        <CIcon :content="$options.freeSet.cilDescription" class="mr-2" /> Ver
                                                                    </CDropdownItem>
                                                                    
                                                                    <CDropdownItem @click="descargarDocumento(doc)">
                                                                        <CIcon :content="$options.freeSet.cilCloudDownload" class="mr-2" /> Descargar
                                                                    </CDropdownItem>

                                                                    <CDropdownDivider v-show="index+1 < documento.documentos.length" />
                                                                </div>
                                                            </CDropdown>
                                                        </div>
                                                        {{documento.mes}} <span v-show="documento.documentos.length==0">(Sin Documentos)</span><!--<CIcon v-show="documento.docs.length==0" :content="$options.freeSet.cilMeh" class="ml-2" />-->
                                                    
                                                            <!--</CCol>
                                                        </CRow>-->
                                                    </CCardHeader>
                                                    <CCollapse :show="documento.isCollapsed" :duration="400">
                                                        <CCardBody>
                                                            <div v-show="documento.documentos.length==0">  
                                                                <CIcon class="mr-2" :content="$options.freeSet.cilMeh" /> No hay documentos
                                                            </div>
                                                            <div v-show="documento.documentos.length>1">
                                                                <CDropdown
                                                                    size="sm"
                                                                    toggler-text="Seleccione Documento a ver"
                                                                    color="dark"
                                                                    class="m-0 mb-1 d-inline-block">
                                                                    <CDropdownItem v-bind:key="doc.nombreDocumento" v-for="doc in documento.documentos" @click="seleccionarDocumentoVer(documento, doc)">{{doc.nombreDocumento}}</CDropdownItem>
                                                                </CDropdown>
                                                            </div>
                                                            <iframe
                                                                v-show="documento.documentos.length>0 && documento.docSeleccionado != null"
                                                                :src="documento.docSeleccionado == null?'':documento.docSeleccionado.viewDocumento.replace('view', 'preview')"
                                                                width="100%"
                                                                height="400"
                                                                allow="autoplay">
                                                            </iframe>
                                                        </CCardBody>
                                                    </CCollapse>
                                                </CCard>
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                </CRow>
                            </CTab>
                            <CTab>
                                <template slot="title">
                                    <CIcon name="cil-user" /> Contratos
                                </template>
                                <CRow style="padding: 10px;">
                                    <CCol>
                                        <CRow v-show="isLoadingContratos">
                                            <CCol>
                                                <center>
                                                    <img src="/img/logo2.png" size="custom-size" :height="30" class="imgr mt-3 mb-3" /> Obteniendo sus Documentos...
                                                </center>
                                            </CCol>
                                        </CRow>
                                        
                                        <CRow v-if="!isLoadingContratos && documentosContratos.length==0">
                                            <CCol sm="12" md="12" lg="12">
                                                <center>
                                                    <img src="/img/logo2.png" size="custom-size" :height="30" class="mt-3 mb-3" /> Su empleador no ha subido documentos
                                                </center>
                                            </CCol>
                                        </CRow> 
                                        <CRow v-show="!isLoadingContratos">
                                            <CCol sm="12" md="6" lg="6" v-bind:key="documento.fechaEmision" v-for="documento in documentosContratos">
                                                
                                                <CCard :class="documento.documento != null ?'tarjetaGestoDocumentosHay':'tarjetaGestoDocumentosNoHay'">
                                                    <CCardHeader>
                                                        <div class="card-header-actions">
                                                            
                                                            <CLink class="card-header-action btn-minimize float-right" @click="documento.isCollapsed = !documento.isCollapsed; (documento.isCollapsed && documento.docSeleccionado == null)?documento.docSeleccionado = documento.documento:0">
                                                                <CIcon :name="`cil-chevron-${documento.isCollapsed ? 'top' : 'bottom'}`"/>
                                                            </CLink>

                                                            <CDropdown class="float-right" :caret="false">
                                                                <template #toggler>
                                                                    <CLink class="card-header-action btn-setting">
                                                                        <CIcon :content="$options.freeSet.cilSettings" />
                                                                    </CLink>
                                                                </template>

                                                                <div v-if="documento.documento == null">
                                                                    <CDropdownItem> 
                                                                        <CIcon class="mr-2" :content="$options.freeSet.cilMeh" />
                                                                        No hay documentos
                                                                    </CDropdownItem>
                                                                </div>
                                                                <div v-else role="group" >
                                                                    
                                                                    <CDropdownHeader>{{documento.documento.nombreDocumento}}</CDropdownHeader>

                                                                    <CDropdownItem @click="seleccionarDocumentoVer(documento, documento.documento); documento.isCollapsed = true">
                                                                        <CIcon :content="$options.freeSet.cilDescription" class="mr-2" /> Ver
                                                                    </CDropdownItem>
                                                                    
                                                                    <CDropdownItem @click="descargarDocumento(documento.documento)">
                                                                        <CIcon :content="$options.freeSet.cilCloudDownload" class="mr-2" /> Descargar
                                                                    </CDropdownItem>

                                                                </div>
                                                            </CDropdown>
                                                        </div>
                                                        {{documento.tipo}} ({{formatFechaDocumentoNormalMesAnio(documento.fechaContrato)}}) <span v-show="documento.documento == null">(Sin Documentos)</span>
                                                        
                                                    </CCardHeader>
                                                    <CCollapse :show="documento.isCollapsed" :duration="400">
                                                        <CCardBody>
                                                            <div v-show="documento.documento == null">  
                                                                <CIcon class="mr-2" :content="$options.freeSet.cilMeh" /> No hay documentos
                                                            </div>
                                                             <iframe
                                                                v-show="documento.documento != null && documento.docSeleccionado != null"
                                                                :src="documento.docSeleccionado == null? '':documento.docSeleccionado.viewDocumento.replace('view', 'preview')"
                                                                width="100%"
                                                                height="400"
                                                                allow="autoplay"
                                                                ></iframe>
                                                        </CCardBody>
                                                    </CCollapse>
                                                </CCard>
                                            </CCol>
                                        </CRow>

                                    </CCol>
                                </CRow>
                                
                            </CTab>

                        </CTabs>
                        
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
    
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    
    import cons from '@/const'
    import axios from "axios"
    import Multiselect from 'vue-multiselect';
    import { freeSet } from "@coreui/icons";

    import { format, formatDistance, formatRelative, subDays } from 'date-fns'
    import es from 'date-fns/locale/es'
    window.locale = 'es'

    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';

    import CargandoInfo from '@/views/utils/CargandoInfo'

    export default {
        name: "DocumentosTrabajador",
        freeSet,
        components: {
            Multiselect,
            CargandoInfo,
            DatePicker
        },
        data() {
            return {
                urlBase: cons.port+"://" + cons.ipServer,
                nombreUsuario: JSON.parse(this.$cookie.get("userLogginToken")).nombreUsuario,
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                
                actTab: 0,
                
                periodos: [],
                isLoadingPeriodos: false,

                periodoSelected: null,
                anioSelected: null,
                
                langPicker: cons.langPicker,

                isLoadingEmpresas: false,
                mostrarEmpresas: false,
                empresasTrabajador: [],
                empresaSelected: {},
                
                isLoadingContratos: false,
                documentosContratos: [],
                isLoadingLiquidaciones: false,
                documentos: [],
                

                columnasDocumentos: [
                    { title: 'Mes', scopedSlots: { customRender: 'nombreMes' }, width: '30%', align: 'center' ,ellipsis: false},
                    { title: 'Documentos', scopedSlots: { customRender: 'documento' }, width: '70%', align: 'center' ,ellipsis: false},
                    /*{ title: 'ENE', scopedSlots: { customRender: 'archivoEnero' }, width: '8.3%', align: 'center' ,ellipsis: false},
                    { title: 'FEB', scopedSlots: { customRender: 'archivoFebrero' }, width: '8.3%', align: 'center' ,ellipsis: false},
                    { title: 'MAR', scopedSlots: { customRender: 'archivoMarzo' }, width: '8.3%', align: 'center' ,ellipsis: false},
                    { title: 'ABR', scopedSlots: { customRender: 'archivoAbril' }, width: '8.3%', align: 'center' ,ellipsis: false},
                    { title: 'MAY', scopedSlots: { customRender: 'archivoMayo' }, width: '8.3%', align: 'center' ,ellipsis: false},
                    { title: 'JUN', scopedSlots: { customRender: 'archivoJunio' }, width: '8.3%', align: 'center' ,ellipsis: false},
                    { title: 'JUL', scopedSlots: { customRender: 'archivoJulio' }, width: '8.3%', align: 'center' ,ellipsis: false},
                    { title: 'AGO', scopedSlots: { customRender: 'archivoAgosto' }, width: '8.3%', align: 'center' ,ellipsis: false},
                    { title: 'SEP', scopedSlots: { customRender: 'archivoSeptiembre' }, width: '8.3%', align: 'center' ,ellipsis: false},
                    { title: 'OCT', scopedSlots: { customRender: 'archivoOctubre' }, width: '8.3%', align: 'center' ,ellipsis: false},
                    { title: 'NOV', scopedSlots: { customRender: 'archivoNoviembre' }, width: '8.3%', align: 'center' ,ellipsis: false},
                    { title: 'DIC', scopedSlots: { customRender: 'archivoDiciembre' }, width: '8.3%', align: 'center' ,ellipsis: false}*/
                ],
                columnasArchivosLiquidaciones: [
                    { title: 'Nombre', scopedSlots: { customRender: 'nombreArchivo' }, width: '20%', align: 'left' ,ellipsis: false},
                    { title: 'Fecha Emisión', scopedSlots:{ customRender: 'fechaEmision' }, width: '20%', align: 'center', ellipsis: false},
                    { title: 'Documentos', scopedSlots: { customRender: 'documentos' }, width: '20%', align: 'center', ellipsis: false},
                ],
            };
        },
        
        beforeMount() {
            let cookieUsername = this.$cookie.get('userLogginToken')
            if(cookieUsername === null || cookieUsername === ""){
                this.$router.push("/pages/login");
            }
            
            this.getEmpresasTrabajador()

        },
        methods: {
            disabledAnio: function(date, currentValue){
                let anioAct = format(date, "yyyy", {locale: es})
                let anioActual = new Date().getFullYear()
                return anioAct > anioActual || anioAct < (anioActual-5)//anioAct >= anioActual && anioAct <= anioActual-5
            },
            getPeriodosTrabajador: function(){
                this.isLoadingPeriodos = true;
                var url = this.urlBase + "/get_periodos_trabajador"
                axios({
                    url: url,
                    method: 'POST',
                    "data": {
                        "info": {
                            "tipoBd": "REMU",
                            "tipoDoc": "liquidaciones",
                            "idEmpresa": this.empresaSelected.idEmpresa,
                        }
                    },                    
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    },

                }).then((response) => {
                    
                    this.periodos = response.data.periodos
                    this.isLoadingPeriodos = false;
                    if(this.validarHayParametros()){
                        if(this.$route.query.tipoDoc === 'liquidaciones'){
                            this.actTab = 0
                            this.periodoSelected = this.periodos.find(periodo => periodo.valor == this.$route.query.periodo)
                            this.obtenerLiquidacionesTrabajador();
                        } else if(this.$route.query.tipoDoc === 'contratos'){
                            this.actTab = 1
                        }
                        
                    }

                })
                .finally(() => {
                    this.isLoadingPeriodos = false;
                });
            },
            getEmpresasTrabajador: function(){
                this.isLoadingEmpresas = true;
                var url = this.urlBase + "/get_empresas_trabajador"
                axios({
                    url: url,
                    method: 'POST',
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    },

                }).then((response) => {
                    
                    this.empresasTrabajador = response.data;
                    if(this.empresasTrabajador.length > 1){
                        this.mostrarEmpresas = true;
                    }

                    if(this.validarHayParametros()){
                        if(this.$route.query.tipoDoc === 'contratos'){
                            this.actTab = 1
                        }
                        if(this.mostrarEmpresas){
                            let empresaSeleccionada = this.empresasTrabajador.find(emp => emp.idEmpresa == this.$route.query.idEmpresa)
                            this.seleccionarEmpresa(empresaSeleccionada)
                        } else{
                            this.seleccionarEmpresa(this.empresasTrabajador[0])
                        }

                    }
                    else{
                        this.seleccionarEmpresa(this.empresasTrabajador[0])
                    }

                    
                    this.getPeriodosTrabajador()
                    

                    this.isLoadingEmpresas = false;

                })
                .finally(() => {
                    this.isLoadingEmpresas = false
                });
            },
            seleccionarEmpresa: function(empresa){
                this.empresaSelected = empresa;
                if(this.anioSelected != null){
                    this.obtenerLiquidacionesTrabajador()
                }
                this.obtenerContratosTrabajador()
                
            },

            seleccionarPeriodo: function(periodo){
                if(this.anioSelected != null)
                    this.obtenerLiquidacionesTrabajador();
                //let anio = format(this.anioSelected, "yyyy", {locale: es})
                //console.log("asdasd", anio, periodo)
                //this.periodoSelected = periodo;
                
            },
            obtenerContratosTrabajador: function(){
                this.isLoadingContratos = true;
                var url = this.urlBase + "/get_documentos_trabajador"
                
                axios({
                    url: url,
                    method: 'POST',
                    "data": {
                        "info": {
                            "tipoBd": "REMU",
                            "tipoDoc": "contratos",
                            "idEmpresa": this.empresaSelected.idEmpresa,
                            "anio": null,
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    },

                }).then((response) => {
                    this.documentosContratos = response.data.documentos
                    
                    if(this.validarHayParametros()){
                        if(this.$route.query.tipoDoc === 'contratos'){
                            this.documentosContratos.forEach(documento => {
                                if(documento.idContrato == this.$route.query.idDoc){
                                    this.seleccionarDocumentoVer(documento, documento.documento)
                                    documento.isCollapsed = true
                                }
                            });
                        }
                    }
                    this.isLoadingContratos = false;

                })
                .finally(() => {
                    this.isLoadingContratos = false
                });

            },
            obtenerLiquidacionesTrabajador: function(){
                this.isLoadingLiquidaciones = true;
                var url = this.urlBase + "/get_documentos_trabajador"
                
                axios({
                    url: url,
                    method: 'POST',
                    "data": {
                        "info": {
                            "tipoBd": "REMU",
                            "tipoDoc": "liquidaciones",
                            "idEmpresa": this.empresaSelected.idEmpresa,
                            "anio": format(this.anioSelected, "yyyy", {locale: es}),//this.periodoSelected.valor,
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    },
                }).then((response) => {
                    this.documentos = response.data.documentos
                    if(this.validarHayParametros()){
                        this.documentos.forEach(documento => {
                            if(documento.numMes == this.$route.query.docMes){
                               this.seleccionarDocumentoVer(documento, documento.documentos[0])
                               documento.isCollapsed = true
                            }
                        });
                    }
                    this.isLoadingLiquidaciones = false;
                    

                })
                .finally(() => {
                    this.isLoadingLiquidaciones = false
                });
            },
            descargarDocumento: function(doc){
                const link = document.createElement('a')
                link.href = doc.urlDocumento
                link.download = doc.nombreDocumento
                link.click()
                URL.revokeObjectURL(link.href)
            },
            seleccionarDocumentoVer(documento, docSeleccionado){
                documento.docSeleccionado = docSeleccionado;
            },
            formatFechaDocumentoNormalMesAnio : function(fechaDoc){
                if (fechaDoc != null) {
                    var fecha = format(new Date(fechaDoc), cons.formatoDocumentosTrabajador, {locale: es})
                    return fecha[0].toUpperCase() + fecha.substring(1);
                } else {
                    return "-";
                }
            },
            validarHayParametros: function(){
                return (Object.keys(this.$route.query).length > 0)
            }
        }
    };
</script>
