<template>
    <CRow v-show="mostrarCarga">
        <CCol md="12">
            <center>
                <img :src="img" size="custom-size" :height="heightImg" class="imgr mt-3 mb-3" /> 
                <span :style="{'color': textColor, 'font-size': textSize}">{{ mensajeInfo }}</span>
            </center>
        </CCol>
    </CRow>

  
</template>
<script>

    import { freeSet } from "@coreui/icons";

    export default {
        name: "CargandoInfo",
        freeSet,
        props: {
            mostrarCarga: Boolean,
            mensajeInfo: String,
            heightImg: {
                type: Number,
                default: 50
            },
            textColor: {
                type: String,
                default: '#29235C'
            },
            textSize: {
                type: String,
                default: '12px'
            },
            img: {
                type: String,
                default: '/img/gesto/logo.png'
            },
        },
        components: { },
        data() {
            return { 
                mensajeInfoDisplay: "", // Texto que se mostrará progresivamente
                dots: "", // Para almacenar los puntos
                intervalId: null, // Para almacenar el ID del intervalo
            };
        },
        watch: {
            mensajeInfo(newValue) {
                console.log("entro", newValue)
                if (newValue.includes("...")) {
                    this.startDotAnimation();
                } else {
                    this.stopDotAnimation();
                    this.mensajeInfoDisplay = newValue; // Mostrar el texto sin animación
                }
            },
        },
        created: function () { },
        beforeMount() { },
        methods: { 
            startDotAnimation() {
                this.stopDotAnimation(); // Detener cualquier animación previa
                this.dots = ""; // Reiniciar los puntos

                this.intervalId = setInterval(() => {
                    if (this.dots.length < 3) {
                    this.dots += ".";
                    } else {
                    this.dots = "";
                    }
                    this.mensajeInfoDisplay = this.mensajeInfo.replace("...", this.dots);
                }, 500); // Cambia el intervalo según el efecto deseado
            },
            stopDotAnimation() {
                if (this.intervalId) {
                    clearInterval(this.intervalId);
                    this.intervalId = null;
                }
            },

        },
    };
</script>